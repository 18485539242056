.container {
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
  width: 40vw;
  min-width: 300px;
  max-width: var(--CARD_MAX_WIDTH);
  position: relative;
  border: 4px solid white;
}

.imageContainer {
  width: 100%;
  text-align: center;
}

.imageContainerBackground {
  background-color: #c7b893;
  background-image: url(../../../assets/wallpapers/redskap-nybak.png);
  background-size: contain;
}

.videoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s;
}

.videoOverlay_show {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
}

.videoContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.video {
  max-width: 100%;
  transition: all 1000ms;
}

.video.videoShow {
  opacity: 1;
  visibility: visible;
}

.video.videoHidden {
  opacity: 0;
  visibility: hidden;
}

.img {
  height: 100%;
}

.fake-img {
  display: none;
  position: fixed;
}

.name {
  font-size: 20px;
  font-weight: 100;
  margin: 0;
}

.subtitle {
  margin: 0;
  font-weight: 100;
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--CARD_BACKGROUND_COLOR);
  flex-direction: column;
}

.videoButton {
  height: 50px;
  margin: 5px;
  border-radius: 100%;
}

.playButtonContainer {
  height: 60px;
  width: 60px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
}

.playZ {
  z-index: 5;
}

.checkContainer {
  height: 40px;
  width: 40px;
  border-bottom-right-radius: 15px;
  border-right: 4px solid white;
  border-bottom: 4px solid white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checked {
  background-color: white;
}

.grayscale {
  filter: grayscale(0.8) brightness(120%);
}

.checked img {
  width: 30px;
  height: 30px;
}

.notChecked img {
  width: 24px;
  height: 24px;
}

.li {
  margin: 10px;
}
