.container {
  background: var(--HEADER_COLOR);
  position: fixed;
  top: 0;
  left: 0;
  height: var(--HEADER-HEIGHT);
  z-index: 100;
  width: 100vw;
  display: flex;
  color: var(--PRIMARY_TEXT_COLOR_LIGHT);
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: var(--PRIMARY_SHADOW);
  -moz-box-shadow: var(--PRIMARY_SHADOW);
  box-shadow: var(--PRIMARY_SHADOW);
}

.back-btn {
  border: none;
  background-color: none;
}

.title {
  padding: 0;
}

.left {
  height: var(--HEADER-HEIGHT);
  width: var(--HEADER-HEIGHT);
  display: flex;
  justify-content: center;
  align-items: center;
}

.center h1 {
  font-size: 25px;
}

.right {
  height: var(--HEADER-HEIGHT);
  /* width: var(--HEADER-HEIGHT); */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
}

.icon {
  height: calc(var(--HEADER-HEIGHT) - 10px);
  width: calc(var(--HEADER-HEIGHT) - 10px);
}

.menu {
  height: calc(var(--HEADER-HEIGHT) - 10px);
  width: calc(var(--HEADER-HEIGHT) - 10px);
}
.containerInfo {
  margin-right: 16px;
  margin-bottom: 4px;
  cursor: pointer;
}

.imgInfo {
  height: calc(var(--HEADER-HEIGHT) - 15px);
  width: calc(var(--HEADER-HEIGHT) - 15px);
}

.infoModal {
  position: absolute;
  top: 75px;
  right: 20px;
  height: 250px;
  width: 400px;
  max-width: 90%;
  border-radius: 8px;
  border: 4px solid var(--HEADER_COLOR);
  background-color: var(--PRIMARY_TEXT_COLOR_LIGHT);
  padding: 20px;
}

.infoExitBtn {
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: 10;
  cursor: pointer;
}

.infoArrow {
  position: absolute;
  z-index: 0;
  top: -28px;
  right: 48px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 26px solid var(--PRIMARY_GREEN);
}

.infoText {
  color: var(--PRIMARY_TEXT_COLOR_DARK);
  font-size: 20px;
  margin: 4px 0;
}

.containerVideo {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.infoVideo {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #b1b1b5;
  border-radius: 4px;
  object-fit: cover;
}
.infoVideoShow {
  display: block;
}
