.container {
  width: 35vw;
  height: 35vw;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  z-index: 10;
  background-color: #b1b1b5;
}

.container video {
  height: 100%;
  width: 100%;
  min-width: 10vw;
  min-height: 10vw;
  object-fit: cover;
  border-radius: 100%;
}
.thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.hideThumbnail {
  transition: opacity 0.5s 0.5s;
  opacity: 0;
}

.container_portrait {
  z-index: 10;
  width: 70vw;
  height: 70vw;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  bottom: 10px;
  margin-bottom: 50px;
}

.container_radius {
  overflow: hidden;
  border-radius: 100%;
}

.container_absolute {
  right: 10px;
  top: 10px;
  position: absolute;
  overflow: hidden;
  border-radius: 100%;
  margin-top: 0;
}

.container.minimized {
  height: 10vw;
  width: 10vw;
  min-height: 100px;
  min-width: 100px;
}

.container.minimized_porttrait {
  height: 100px;
  width: 100px;
  margin-bottom: 0px;

  height: 100px;
  width: 100px;
  position: absolute;
  bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  left: 0;
  right: 0;
}

.play_button {
  position: fixed;
  height: 100px;
  width: 100px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play_button.pb_show {
  opacity: 1;
  visibility: visible;
  height: 50px;
  width: 50px;
}

@keyframes signPlayerFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay_sign {
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s;
}

.overlay_sign_show {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
